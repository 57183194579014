import { Controller } from 'stimulus'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default class extends Controller {
  static targets = ['prev', 'next', 'counter']

  connect() {
    new Swiper(this.element, {
      autoplay: 6000,
      slidesPerView: 1.5,
      spaceBetween: 15,
      loop: true,
      breakpoints: {
        768: {
          slidesPerView: 2.5,
          spaceBetween: 30,
        },
      },
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
      runCallbacksOnInit: true,
      onInit: function (sw) {
        this.counterTarget.innerHTML = sw.activeIndex + 1 + '/' + sw.slides.length
      },
      onSlideChangeEnd: function (sw) {
        this.counterTarget.innerHTML = sw.activeIndex + 1 + '/' + sw.slides.length
      },
    })
  }
}
