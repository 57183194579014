import Turbolinks from 'turbolinks'
import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import 'controllers'
import 'alpinejs'
import 'typeface-crimson-text'
import 'typeface-nunito'
import '../css/application.scss'
import '../scripts/analytics'

Turbolinks.start()
Rails.start()
ActiveStorage.start()
require.context('../images', true)
